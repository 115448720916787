<script setup lang="ts">
const stackItems = ref([
  {
    icon: '/images/stack/icon-white.svg',
    title: 'Nuxt.js',
    color: '#00DC82',
    link: 'https://nuxtjs.org',
    hiddenOnMobile: false
  },
  {
    icon: '/images/stack/js.svg',
    title: 'JavaScript',
    color: '#f7df1e',
    link: 'https://nuxtjs.org',
    hiddenOnMobile: false
  },
  {
    icon: '/images/stack/Swift_logo_color.svg',
    title: 'Swift',
    color: '#F05138',
    link: 'https://nuxtjs.org',
    hiddenOnMobile: false
  },
  {
    icon: '/images/stack/Kotlin_Icon_2021.svg',
    title: 'Kotlin',
    color: '#ffffff',
    link: 'https://nuxtjs.org',
    hiddenOnMobile: false
  },
  {
    icon: '/images/stack/k8s.svg',
    title: 'Kubernetes',
    color: '#326de6',
    link: 'https://nuxtjs.org',
    hiddenOnMobile: false
  },
  {
    icon: '/images/stack/Logomark_Full Color.svg',
    title: 'Firebase',
    color: '#ffffff',
    link: 'https://nuxtjs.org',
    hiddenOnMobile: false
  },
  {
    icon: '/images/stack/icon-white.svg',
    title: 'Strapi',
    color: '#5445fa',
    link: 'https://nuxtjs.org',
    hiddenOnMobile: false
  },
  {
    icon: '/images/stack/tailwindcss-mark.svg',
    title: 'Tailwind',
    color: '#38bdf8',
    link: 'https://nuxtjs.org',
    hiddenOnMobile: false
  },
  {
    icon: '/images/stack/supabase-logo-icon.svg',
    title: 'supabase',
    color: '#ffffff',
    link: 'https://nuxtjs.org',
    hiddenOnMobile: false
  },
  {
    icon: '/images/stack/NestJS-logo-wordmark.svg',
    title: 'Nest.js',
    color: '#ffffff',
    link: 'https://nuxtjs.org',
    hiddenOnMobile: true
  }
])
</script>
<template>
  <div class="bg-gradient text-secondary-content">
    <div class="container mx-auto px-8 py-20 md:py-36 max-w-screen-sm">
      <ClientOnly fallback-tag="div">
        <h2 class="text-2xl text-center md:text-3xl lg:text-4xl font-bold pb-6">
          <RoughNotation :is-show="true" type="highlight">
            <span class="text-accent-content">Unser Tech-Stack</span>
          </RoughNotation>
        </h2>
        <template #fallback>
          <h2 class="text-xl text-center md:text-3xl lg:text-4xl font-bold pb-6">
            Unser Tech-Stack
          </h2>
        </template>
      </ClientOnly>
      <div class="flex flex-col justify-center mt-12">
        <div class="grid grid-cols-3 md:grid-cols-5 gap-6">
          <template v-for="item in stackItems" :key="item.title">
            <TechStackCell
              :icon="item.icon"
              :color="item.color"
              :title="item.title"
              :link="item.link"
              :class="[item.hiddenOnMobile ? 'hidden md:block' : '']"
              data-aos="fade-up"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped>
.bg-gradient {
  background: linear-gradient(to bottom, #18284a, #18284a, #14213d);
}
</style>
