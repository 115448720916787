<script setup lang="ts">
const properties = defineProps({
  color: {
    type: String,
    required: true
  },
  icon: {
    type: String,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  link: {
    type: String,
    required: true
  }
})
</script>

<template>
  <div>
    <div class="rounded-lg aspect-square" :style="{ backgroundColor: properties.color }">
      <div class="text-center">
        <img
          :src="properties.icon"
          :alt="properties.title"
          :width="300"
          :height="300"
          class="p-3"
        />
      </div>
    </div>
    <h3 class="text-center text-sm pt-2 font-bold">{{ properties.title }}</h3>
  </div>
</template>
