import 'vanilla-cookieconsent/dist/cookieconsent.css'
import * as CookieConsent from 'vanilla-cookieconsent'

import type { App } from 'vue'

import '@/assets/css/cookieconsent.css'

// function logConsent() {
//   // Retrieve all the fields
//   const cookie = CookieConsent.getCookie()
//   const preferences = CookieConsent.getUserPreferences()

//   // In this example we're saving only 4 fields
//   const userConsent = {
//     consentId: cookie.consentId,
//     acceptType: preferences.acceptType,
//     acceptedCategories: preferences.acceptedCategories,
//     rejectedCategories: preferences.rejectedCategories
//   }
// }

const config: CookieConsent.CookieConsentConfig = {
  revision: 1,
  categories: {
    necessary: {
      enabled: true,
      readOnly: true,
      services: {
        sentry: {
          label: 'Sentry'
        }
      }
    },
    performance: {
      enabled: false,
      readOnly: false,
      services: {
        matomo: {
          label: 'Matomo'
        },
        hubspot: {
          label: 'HubSpot'
        },
        hubspot_forms: {
          label: 'HubSpot Forms'
        }
      }
    }
  },

  disablePageInteraction: true,

  guiOptions: {
    consentModal: {
      layout: 'bar inline',
      position: 'bottom center',
      flipButtons: false,
      equalWeightButtons: true
    },

    preferencesModal: {
      layout: 'box',
      flipButtons: false,
      equalWeightButtons: true
    }
  },

  language: {
    default: 'de',

    translations: {
      de: {
        consentModal: {
          title: 'Hinweis zum Datenschutz',
          description:
            'Wir verwenden Cookies, um Ihnen die optimale Nutzung unserer Webseite zu ermöglichen. Es werden für den Betrieb der Seite notwendige Cookies gesetzt. Darüber hinaus können Sie Cookies für Statistikzwecke zulassen.',
          acceptAllBtn: 'Alle auswählen',
          acceptNecessaryBtn: 'Alle abwählen',
          showPreferencesBtn: 'Individuelle Einstellungen'
        },
        preferencesModal: {
          title: 'Deine Cookie-Einstellungen',
          acceptAllBtn: 'Alle auswählen',
          acceptNecessaryBtn: 'Alle abwählen',
          savePreferencesBtn: 'Einstellungen speichern',
          closeIconLabel: 'Schließen',
          sections: [
            {
              description: 'Bitte wähle und bestätige deine Cookie-Einstellungen.'
            },
            {
              title: 'Unbedingt erforderliche Cookies',
              description:
                'Diese Cookies sind für den Betrieb der Webseite unbedingt erforderlich. Ohne diese Cookies würde die Webseite nicht richtig funktionieren.',
              linkedCategory: 'necessary'
            },
            {
              title: 'Funktion und Leistung',
              description:
                'Diese Cookies ermöglichen es uns, die Nutzung der Webseite zu analysieren, um die Leistung zu messen und zu verbessern.',
              linkedCategory: 'performance'
            },
            {
              title: 'weitere Informationen',
              description:
                'Für weitere Informationen zu Cookies und Ihren Einstellungen besuchen Sie bitte unsere <a href="/datenschutz">Datenschutzrichtlinie</a>'
            }
          ]
        }
      }
    }
  },
  onFirstConsent: () => {
    // logConsent()
    reloadNuxtApp({ force: true })
  },
  onChange: () => {
    // logConsent()
    reloadNuxtApp({ force: true })
  }
}

const ConsentPluginVue = {
  install: (app: App) => {
    app.config.globalProperties.$CookieConsent = CookieConsent
    app.config.globalProperties.$CookieConsent.run(config)
  }
}

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.use(ConsentPluginVue)
})
