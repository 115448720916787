<script setup lang="ts">
import 'aos/dist/aos.css'
import AOS from 'aos'

onMounted(() => {
  AOS.init()
})

defineComponent({
  name: 'IndexPage'
})

useSeoMeta({
  title: 'dreißigelf - Digital. Innovativ. Verbunden.',
  articleModifiedTime: '2024-12-19'
})
</script>

<template>
  <div>
    <LazyHero />
    <LazyCompanies />
    <LazyServices />
    <LazyTechStack />
  </div>
</template>
