<script setup lang="ts">
import '~/assets/css/main.css'
const ccCookie = useCookie('cc_cookie')

useScript(
  {
    src: 'https://js-eu1.hs-scripts.com/145188840.js',
    async: true,
    defer: true,
    type: 'text/javascript',
    id: 'hs-script-loader'
  },
  {
    bundle: true,
    trigger: useScriptTriggerConsent({
      consent: ccCookie.value?.services?.performance?.includes('hubspot') ?? false
    })
  }
)
</script>

<template>
  <div data-theme="dreissigelf" class="antialiased">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
