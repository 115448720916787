<script setup lang="ts">
import type { NuxtError } from '#app'

const properties = defineProps({
  error: {
    type: Object as () => NuxtError,
    required: true
  }
})
</script>

<template>
  <div class="flex items-center justify-center h-screen bg-secondary">
    <div class="p-4 space-y-4 text-center">
      <h1 class="text-4xl font-bold text-gray-200">Error</h1>
      <div>
        <p class="text-error pb-4">{{ properties.error.message }}</p>
      </div>
      <NuxtLink to="/" class="text-blue-500 hover:underline"> Go back to the home page </NuxtLink>
    </div>
  </div>
</template>
